/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  /* CSS */


}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {

  /* CSS */

}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {

  /* CSS */

}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* CSS */

}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */

  .inputStyle {
    width: 2.7rem !important;
    margin: .2rem;
  }

}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {

  /* CSS */
  .onboarding {
    height: auto !important;
  }

  .onboarding__left {
    height: auto !important;
  }

  .onboarding__right {
    background: #0000 !important;
    padding: 10px !important;
  }

  .inputStyle {
    width: 2.7rem !important;
    margin: .2rem !important;
  }

}