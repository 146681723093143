:root {
    --sparkle-green: #46B839;
    --sparkle-bright-lime: #97D700;
    --sparkle-bright-yellow: #EEDC00;
    --sparkle-beige: #FFC845;
    --sparkle-bright-orange: #FF6900;
    --sparkle-deep-blue: #14233B;
    --white: #fff;
}

@import './media-queries.css';

/** Proxima Nova **/
@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Black-webfont.woff2') format('woff2'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Black-webfont.woff') format('woff'),
        url('./fonts/MarkSimonson-Proxima-Nova-Alt-Black-webfont.ttf') format('truetype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Black-webfont.svg#proxima_nova_altblack') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Bold-webfont.woff2') format('woff2'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Bold-webfont.woff') format('woff'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Bold-webfont.ttf') format('truetype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Bold-webfont.svg#proxima_nova_altbold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Regular-webfont.eot');
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Regular-webfont.woff') format('woff'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Regular-webfont.ttf') format('truetype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Alt-Regular-webfont.svg#proxima_nova_altregular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Semibold-webfont.eot');
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Semibold-webfont.woff2') format('woff2'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Semibold-webfont.ttf') format('truetype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Semibold-webfont.svg#proxima_nova_ltsemibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Thin-webfont.eot');
    src: url('./fonts/Mark-Simonson-Proxima-Nova-Thin-webfont.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Thin-webfont.woff2') format('woff2'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Thin-webfont.woff') format('woff'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Thin-webfont.ttf') format('truetype'),
        url('./fonts/Mark-Simonson-Proxima-Nova-Thin-webfont.svg#proxima_nova_ltthin') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Mont';
    src: url('./fonts/Mont-ExtraLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('./fonts/Mont-Heavy.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Proxima Nova';
    font-size: 16px;
    overflow-x: hidden !important;
    font-weight: 400;
}

.font-14 {
    font-size: 14px;
}


.sparkle-black {
    color: #000 !important;
}

.sparkle--weight__700 {
    font-weight: 700;
}

.sparkle--mont__900 {
    font-family: 'Mont';
    font-weight: 900;
}

.sparkle--font__14 {
    font-size: 14px;
}

.sparkle--font__18 {
    font-size: 18px;
}

.sparkle--font__24 {
    font-size: 24px;
}


.sparkle--font__36 {
    font-size: 36px;
}

.sparkle--font__56 {
    font-size: 56px;
}


.sparkle--color__light {
    color: #5A7184;
}

.sparkle--color__deep-blue {
    color: var(--sparkle-deep-blue) !important;
}

.sparkle--color__bright-orange {
    color: var(--sparkle-bright-orange) !important;
}

.sparkle--color__green {
    color: var(--sparkle-green) !important;
}

.sparkle--color__bright-yellow {
    color: var(--sparkle-bright-yellow) !important;
}

.bg__sparkle--black {
    background-color: #000;
}


.sparkle--button__green {
    background: var(--sparkle-green);
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    filter: drop-shadow(0px 10px 25px rgba(21, 37, 72, 0.1));
}

.sparkle--button__green:hover {
    background: #46b839cc;
    color: #fff;
}

.sparkle--button__green_outline {
    background: #fff;
    border-radius: 4px;
    color: var(--sparkle-green);
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    border: 1px solid var(--sparkle-green);
}

.sparkle--button__green_outline:hover {
    background: #97D700;
    color: #fff;
    border: 1px solid #97D700;
}

.sparkle--button__bright-lime {
    background: var(--sparkle-bright-lime);
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    filter: drop-shadow(0px 10px 25px rgba(21, 37, 72, 0.1));
}

.sparkle--button__bright-lime:hover {
    background: #7eb303;
    color: #fff;
}

.sparkle--button__bright-lime_outline {
    background: #fff;
    border-radius: 4px;
    color: #97D700;
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    border: 1px solid #97D700;
}

.sparkle--button__bright-lime_outline:hover {
    background: #97D700;
    color: #fff;
}

.sparkle--button__orange {
    background: var(--sparkle-bright-orange);
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    filter: drop-shadow(0px 10px 25px rgba(21, 37, 72, 0.1));
}

.sparkle--button__orange:hover {
    background: #e76202;
    color: #fff;
}


.sparkle--button__beige {
    background: var(--sparkle-beige);
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    filter: drop-shadow(0px 10px 25px rgba(21, 37, 72, 0.1));
}

.sparkle--button__orange:hover {
    background: #f9c03a;
    color: #fff;
}



.sparkle--button__blue {
    background: var(--sparkle-deep-blue);
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    filter: drop-shadow(0px 10px 25px rgba(21, 37, 72, 0.1));
}

.sparkle--button__blue:hover {
    filter: opacity(.8);
    color: #fff;
}

.sparkle--button__blue-border {
    border: 1px solid var(--sparkle-deep-blue);
    border-radius: 4px;
    color: var(--sparkle-deep-blue);
    font-weight: 600;
    padding-top: .8em;
    padding-bottom: .8em;
    filter: drop-shadow(0px 10px 25px rgba(21, 37, 72, 0.1));
}

.sparkle--button__blue-border:hover {
    filter: opacity(.8);
    color: var(--sparkle-deep-blue);
}



.sparkle--paragraph {
    font-size: 18px;
    color: #5A7184;
    line-height: 32px;
    font-weight: 400;
}

.sparkle--paragraph_deepblue {
    font-size: 18px;
    color: var(--sparkle-deep-blue);
    line-height: 32px;
    font-weight: 400;
}


/* Onboarding */
.onboarding {
    height: 100vh;
}

.onboarding__left {
    height: 100vh;
}

.onboarding__left h1 {
    font-size: 64px;
    line-height: 69px;
}

.onboarding__left p {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: var(--sparkle-deep-blue);
    opacity: 0.8;
}

.onboarding__right {
    background: #EEEEEE;
    padding: 80px 150px;
}

.onboarding__right .heading {
    font-weight: bold;
    font-size: 28px;
    /* line-height: 28px; */
}

.onboarding__right .feature_card {
    font-size: 17px;
    line-height: 17px;
    color: var(--white);
    padding: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.description--wrapper {
    width: 70%;
}

.onboarding__right .feature_card p {
    line-height: 25px;
}

.registration__form h2 {
    font-weight: 700;
    font-size: 34px;
    letter-spacing: -0.02em;
    color: #183B56;
}

.payments {
    background-image: url('../images/payment.jpg');
}

.lending {
    background-image: url('../images/lending.jpg');
}

.loyalty {
    background-image: url('../images/loyalty.jpg');
}

/* form styles */

.__form input.form-control {
    border-color: #C3CAD9;
    background-color: #fff;
    height: 56px;
    border-radius: 8px;
}

.__form textarea.form-control {
    border-color: #C3CAD9;
    background-color: #fff;
}

.__form .custom-select {
    height: 56px;
}

.__form .form-control::placeholder {
    font-weight: 400;
}

.__form .btn {
    height: 56px;
}

.__form label {
    color: #5A7184;
    font-weight: 600;
    font-size: 15px !important;
}

.footer__disclaimer {
    color: #fff;
    font-size: 14px !important;
}

/* Dashboard styles */
.dashboard-body {
    overflow-x: hidden;
    background: #fff !important;
}

.dashboard_nav {
    padding: 15px 10px;
    background: #0000;
    border: none;
    border-radius: 0;
    margin-bottom: 0px;
}

.notification-bell {
    background: #EAEDF5;
    border-radius: 8px;
}

.top_nav {
    width: 100%;
    z-index: 5;
}

.cursur {
    cursor: pointer;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.dashboard-body .navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

thead tr th,
tfoot tr th {
    font-size: 14px !important;
    font-weight: 700;
    color: #14233bc4;
    padding: .9rem !important;
}

.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(234, 237, 245, 0.6);
    border-bottom: 1px solid rgba(234, 237, 245, 0.6);
}

table tr td {
    font-size: 14px !important;
    font-weight: normal;
    color: #14233B;
    padding: .9rem !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(234, 237, 245, .3);
}

.table thead.bt-0 th {
    border-top: none;
}

/* ---------------------------------------------------
SIDEBAR STYLE
----------------------------------------------------- */
.weight-semi-bold {
    font-weight: 500;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    /* height: 90vh; */
    height: 100%;
    z-index: 999;
    text-align: center;
    font-size: 1em;
    background: var(--sparkle-deep-blue);
    color: #fff;
    transition: all 0.3s;
    overflow-y: auto;
    /*    box-shadow: 5px -5px 7px rgba(0, 0, 0, 0.08);*/
}

#sidebar.active {
    min-width: 200px;
    max-width: 200px;
    text-align: left;
}

#sidebar.active .sidebar-header {
    display: block;
}

#sidebar ul li a {
    text-align: left;
}

#sidebar.active ul li a,
#sidebar-2.active ul li a {
    padding: 10px 10px;
    text-align: left;
    font-size: 1em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar .sidebar-header {
    padding: 10px 25px;
    text-align: left;
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components,
#sidebar-2 ul.components {
    padding: 20px 0 0 0;
    margin-bottom: 0px;
}

#sidebar ul li a {
    padding: 10px 25px;
    font-size: 18px;
    display: block;
    color: #fff;
    font-weight: bold;
    filter: brightness(0.6);
}

#sidebar ul li a:hover {
    /* color: var(--sparkle-green); */
    background: var(--sidebar-hover);
    filter: grayscale(0%);
    filter: brightness(1);
}

#sidebar ul li a i {
    margin-right: 10px;
}

#sidebar ul li>a.active,
#sidebar a[aria-expanded="true"] {
    color: var(--sparkle-green);
    background: var(--sidebar-hover);
    filter: grayscale(0%);
}

#sidebar ul li>a.active svg path {
    fill: var(--sparkle-green);
    background: var(--sidebar-hover);
    filter: grayscale(0%);
}

a[data-toggle="collapse"] {
    position: relative;
}


#sidebar a[aria-expanded="true"] {
    color: #1679e2;
    text-decoration: underline;
}


.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    /*	background: #14c660;*/
}

.navbar-nav .dropdown-menu {
    position: absolute !important;
    float: none;
}

/* ---------------------------------------------------
CONTENT STYLE
----------------------------------------------------- */
.text-dull {
    color: #858585;
}

#content {
    width: calc(100% - 250px);
    /* padding: 40px 10px; */
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #F7FAFD;
}

#content.active {
    width: 100%;
}

.main {
    padding: 20px 80px 0px;
}

.dash_card {
    background: #FFFFFF;
    border: 2px solid #E5EAF4;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
}

.page-title {
    font-weight: 900;
    font-size: 34px;
    line-height: 39px;
    letter-spacing: -0.02em;
    color: #14233B;
}

.page-desc {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #14233B;
    margin-bottom: 1.5rem !important;
}

.page-back {
    background: rgba(20, 35, 59, 0.1);
    color: var(--sparkle-deep-blue);
    font-weight: 600;
    font-size: 18px;
    border-radius: 20px;
}

/* overview area */
.overview_card {
    background: #FFFFFF;
    border: 1px solid #E5EAF4;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.overview_card .title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    color: rgba(20, 35, 59, .85);
}

.overview_card .value {
    font-weight: 900;
    font-size: 30px;
    color: rgba(20, 35, 59, .85);
}

.overview_card .table thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6;
    border-top: 0px solid #dee2e6;
    color: #14233B;
    font-weight: bold;
    /* font-size: 15px !important; */
}


.redeemed {
    background: rgba(15, 185, 73, 0.1);
    border-radius: 5px;
    color: #0FB949;
    font-weight: 600;
    width: 120px;
}

.pending {
    background: rgba(255, 122, 0, 0.1);
    border-radius: 5px;
    color: rgba(255, 122, 0, 1);
    font-weight: 600;
    width: 120px;
}

.failed {
    background: rgba(234, 81, 48, 0.1);
    border-radius: 5px;
    color: rgba(234, 81, 48, 1);
    font-size: 14px;
    width: 120px;
}

.expired {
    background: #6f7a8d7c;
    border-radius: 5px;
    color: var(--sparkle-deep-blue);
    font-size: 14px;
    width: 120px;
}

.actions {
    border-bottom: 1.5px solid #E5EAF4;
}

.chart_toggle {
    background: rgba(20, 35, 59, 0.05);
    border-radius: 8px;
    align-items: center;
}

.chart_toggle span {
    font-weight: bold;
}

.chart_toggle button {
    border-radius: 4px;
    font-weight: 600;
    font-weight: bold;
    color: var(--sparkle-deep-blue);
}

.chart_toggle button.active {
    background: #97D700;
    border-radius: 4px;
    color: #fff;
}

.chart_toggle button:hover {
    background: #97D700;
    color: #fff;
}


/* Switch */

.switch {
    position: relative;
    display: inline-block;
    width: 29px;
    height: 12px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(20, 35, 59, .6);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0px;
    bottom: -1.9px;
    background-color: #14233B;
    -webkit-transition: .4s;
    transition: .4s;
    /* opacity: 0.4; */
}

input:checked+.slider {
    background-color: #BFE763;
}

input:checked+.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: -13px !important;
    bottom: -1.8px;
    background-color: #46B839;
    -webkit-transition: .4s;
    transition: .4s;
    /* opacity: 0.4; */
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.vendor_name {
    color: #0C2344;
    font-weight: 900;
    font-size: 14px;
}

.vendor_role {
    color: #6F7A8D;
    font-weight: 700;
    font-size: 14px;
}

.vendor_id {
    color: #6F7A8D;
    font-size: 13px;
}

.very-small {
    font-size: 9px;
}

.modal__form input.form-control {
    height: 48px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.align-center {
    align-items: center;
}

.dash-footer-text {
    position: relative;
    bottom: 0;
    width: 100%;
}

.dash-footer-text p {
    color: #939AA6;
    font-size: 14px;
}

.dash_wrapper {
    background: #0000;
    /* height: 75vh; */
    border: 2px solid #EFF1F3;
    box-sizing: border-box;
    border-radius: 8px;
}

.min-vh-75 {
    min-height: 75vh !important;
}

.waitlist__form input.form-control {
    border-color: #C3CAD9;
    background-color: #fff;
    height: 56px;
}


.inputStyle {
    width: 2.7rem !important;
    margin: 0 1rem;
    border-radius: 8px;
}

span.modal-close-btn {
    position: absolute;
    right: 30px;
    top: 25px;
    z-index: 5;
    cursor: pointer;
}

.pill {
    background: #FFFFFF;
    border-radius: 20px;
    height: 10px;
    flex-grow: 1;
    margin: 0 10px;
}

.pill.active {
    background: #97D700;
}

/*DASHBOARD STYLES END*/


.nav-tabs .nav-link.active {
    padding: 1rem 2rem;
    color: #46B839;
    border-color: #0000 #0000 #46B839;
    background-color: #0000;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 3px solid #46B839;
}

.nav-tabs .nav-link.active:hover {
    border-bottom: 3px solid #46B839;

}

.nav-tabs .nav-link {
    padding: 1rem 2rem;
    background-color: #0000;
}

.nav-tabs .nav-link:hover {
    border-color: #0000 #0000 #0000;
    color: #46B839;
}

.nav-tabs {
    border-bottom: 2px solid #EFF1F3;
    color: #939AA6;
}

.btn-shadow {
    box-shadow: 0px 10px 25px rgb(21 37 72 / 10%);
}

.offer .offer__card {
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    position: relative;
}

.offer .offer__card img {
    border-radius: 0px 0px 35px 0px;
    object-fit: cover;
    object-position: top;
    max-height: 80px;
    filter: brightness(50%);
}

.offer .offer__card p {
    color: #14233bc7;
    font-size: 13px;
}

.offer .offer__card p.description {
    color: #14233bc7;
    font-size: 13px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
}

.offer .offer__card h6 {
    color: #fff;
    position: absolute;
    top: 25%;
    left: 15px;
    font-weight: 600;
}

.offer_expired {
    opacity: 0.5;
}

.user__card {
    background: #FFFFFF;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    color: #14233bab;
}

.user__card .name {
    font-weight: bold;
    font-size: 26px;
}

.user__card .email {
    font-size: 16px;
}

.user__card .role {
    color: #14233b68;
}

.modal-title {
    font-size: 18px;
    color: #0C2344;
}

.modal-header {
    border-bottom: 2px solid #eaedf58a
}

.modal-45w {
    width: 45%;
    max-width: none !important;
}

.modal-50w {
    width: 50%;
    max-width: none !important;
}

.modal-prompt {
    width: 40%;
    max-width: none !important;
}

.modal-prompt p {
    color: rgba(20, 35, 59, .5)
}

.modal-backdrop {
    background-color: var(--sparkle-deep-blue);
}


/*------------------------------------*\ #PAGINATION\*------------------------------------*/
.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    margin: 15px 0 15px;
    padding: 10px 20px;
}

/* .pagination button {
    margin-right: 10px;
    display: inline-block;
} */

.pagination button {
    background-color: #f7f6f6;
    color: #171A1F;
    font-family: "Gotham Book", sans-serif;
    font-size: 14px;
    font-weight: normal;
    border: none;
    text-transform: capitalize;
    padding: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    border: 1px solid #dadada;
}



.pagination button.next,
.pagination button.prev {
    width: 100%;
    padding: 0 23px;
}

.pagination button[disable] {
    pointer-events: none;
    cursor: default;
    color: #CAD6E3;
}

.pagination button:not([disabled]) {
    border: 1px solid var(--sparkle-deep-blue) !important;
    color: var(--sparkle-deep-blue);
    /* background: #96d70096; */
}

.pagination button:hover {
    box-shadow: 0px 10px 20px rgb(56 63 70 / 15%);
}

.pagination>li:hover>button,
.pagination>li>button:focus,
.pagination>li>button:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: #197CDD;
    -webkit-box-shadow: 0px 10px 20px rgba(39, 140, 239, 0.25);
    box-shadow: 0px 10px 20px rgba(39, 140, 239, 0.25);
}

@media only screen and (max-width:991px) {
    .pagination>li {
        margin: 6px;
    }
}

.info img {
    height: 150px;
    width: 150px;
    border: 2.5px solid #183B56;
}

.voucher-back-button {
    background: rgba(70, 184, 57, .16);
    border-radius: 40px;
    color: #46B839;
    font-weight: 600;
}

.voucher-back-button:hover {
    background: rgba(70, 184, 57, .3);
    border-radius: 40px;
    color: #46B839;
    font-weight: 600;
}

.voucher-image {
    position: relative;
}

.voucher-image img {
    border-radius: 0px 0px 35px 0px;
    object-fit: cover;
    object-position: center;
    max-height: 160px;
    filter: brightness(50%);
}

.voucher-image h6 {
    color: #fff;
    position: absolute;
    top: 25%;
    left: 15px;
    font-weight: 600;
    font-size: 2.5em;
}

/* ---------------------------------------------------
MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
        height: 100%;
    }

    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

    #sidebar.active {
        margin-left: 0 !important;
    }

    #sidebar .sidebar-header strong {
        display: block;
    }

    #sidebar ul li a {
        padding: 20px 10px;
    }

    #sidebar ul li a span {
        font-size: 0.85em;
    }

    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }

    #sidebar ul ul a {
        padding: 10px !important;
    }

    #sidebar ul li a i {
        font-size: 1.3em;
    }

    #sidebar {
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }

    #content {
        width: 100%;
        padding: 40px 10px;
        min-height: 100vh;
        transition: all 0.3s;
        position: absolute;
        top: 0;
        right: 0;
    }

    .nav-link {
        display: block;
        padding: .5rem 1rem !important;
    }

    .dash_wrapper {
        height: auto;
    }

    .dash-footer-text {
        position: relative;
        bottom: 0;
        width: 100%;
    }

    .modal-45w {
        width: 95%;
        max-width: none !important;
    }

    .modal-50w {
        width: 95%;
        max-width: none !important;
    }

    .modal-prompt {
        width: 95%;
        max-width: none !important;
    }

}






/*
The loaders use CSS custom properties (variables) to control the attributes of the loaders
*/
:root {
    --loader-width: 70px;
    --loader-height: 70px;
    --loader-color-primary: #97D700;
    --loader-color-secondary: #eee;
    --line-width: 3px;
    --animation-duration: 2s;
    --loader-initial-scale: 0.1;
}

.loader,
.loader:before,
.loader:after {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
}

/*
In order to get optimal results, please only change the 
variables above and don't change anything in the actual loader code
*/



@keyframes circle-loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader.simple-circle {
    transform-origin: center center;
    border: var(--line-width, 4px) solid var(--loader-color-secondary, #fff);
    border-right-color: var(--loader-color-primary, #00f);
    width: var(--loader-width, 100px);
    height: var(--loader-height, 100px);
    border-radius: 50%;
    animation: circle-loader var(--animation-duration, 1s) infinite ease-out;
}

.left-input {
    padding-left: 26px;
}

.left-inset {
    position: absolute;
    left: 5px;
    top: 29%;
    background: #fff;
    padding: 0 5px;
    font-weight: bold;
    color: #14233bad;
}

.right-inset {
    position: absolute;
    top: 25%;
    right: 10px;
    top: 25%;
    background: #fff;
    padding: 0 5px;
    font-weight: bold;
    color: #14233bad;
}

.onboard-modal {
    width: 500px;
}

.onboard-modal h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;

    color: #183B56;
}

.developer_area .form-control:focus {
    color: #495057;
    background-color: #0000;
    border-color: #0000;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.developer_area label {
    font-family: 'Proxima Nova Alt';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: -0.04em;
    color: #183B56;
}

.form-control[readonly] {
    background-color: #0000;
    opacity: 1;
}

.copy_wrapper {
    background: #F7FAFD;
    border: 1px solid #E6E8EC;
    box-sizing: border-box;
    border-radius: 5px;
    padding-right: 8px;
}

.docs_widget {
    background: #FFFFFF;
    border: 1px solid #E5EAF4;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    color: #183B56;
}

.docs_widget h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

.docs_widget p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.04em;
}

.quick_tour {
    background: #FFFFFF;
    border-radius: 5px;
}

.quick_tour p {
    font-weight: 700;
    font-size: 14px;
    /* line-height: 16px; */
    color: #14233B;
}

.react-joyride__spotlight {
    border-radius: 4px;
    position: fixed;
    background-color: #0000;
    height: 67px;
    left: -10px;
    opacity: 1;
    pointer-events: auto;
    top: 151px;
    transition: opacity 0.2s ease 0s;
    width: 260px !important;
    color: red !important
}

.react-joyride__overlay {
    background-color: rgba(20, 35, 59, 0.8) !important;
}

@keyframes joyride-beacon-inner {
    20% {
        opacity: 0.9;
    }

    90% {
        opacity: 0.7;
    }
}

@keyframes joyride-beacon-outer {
    0% {
        transform: scale(1);
    }

    45% {
        opacity: 0.7;
        transform: scale(0.75);
    }

    100% {
        opacity: 0.9;
        transform: scale(1);
    }
}


[data-action="back"] {
    /* Attribute has this exact value */
    background: #fff !important;
    border-radius: 4px !important;
    color: var(--sparkle-green) !important;
    font-weight: 600 !important;
    padding-top: .8em !important;
    padding-bottom: .8em !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    border: 1px solid var(--sparkle-green) !important;
    margin-right: auto !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

[data-action="back"]:hover {
    background: #46b839cc !important;
    border: #46b839cc !important;
    color: #fff !important;
}

[data-action="primary"] {
    /* Attribute has this exact value */
    background: var(--sparkle-green) !important;
    border-radius: 4px !important;
    color: #fff !important;
    font-weight: 600 !important;
    padding-top: .8em !important;
    padding-bottom: .8em !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;

    border: 1px solid var(--sparkle-green) !important;
}

[data-action="primary"]:hover {
    background: #46b839cc !important;
    border: #46b839cc !important;
    color: #fff;
}

[data-action="close"] {
    background-color: rgba(70, 184, 57, 0.2) !important;
    border: 0px;
    border-radius: 50px !important;
    cursor: pointer;
    font-size: 10px !important;
    line-height: 1;
    padding: 12px !important;
    appearance: none;
    position: absolute;
    right: 5px !important;
    top: 5px !important;
}

[data-action="close"] svg path {
    fill: rgba(70, 184, 57, 0.8)
}

.react-joyride__tooltip div {
    text-align: left;
    padding-left: 0px !important;
}

.react-joyride__tooltip h4 {
    text-align: left !important;
    font-weight: 900;
    font-size: 22px !important;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #14233B;
}

.offline {
    position: absolute;
    box-shadow: 1px 3px 5px rgb(201 206 216 / 30%);
    border-radius: 5px;
    padding: 10px;
    z-index: 15;
    right: 15px;
    bottom: 15px;
    background-color: #fff;
    border: 1px dashed var(--sparkle-deep-blue);
    width: 20%;
    background-color: rgb(47, 47, 54);
    font-size: 14px;
    color: rgb(185, 194, 203);
}